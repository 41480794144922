#species-select-container {
    input.is-invalid {
        border: 1px solid #dc3545;
    }

    .option-container {
        width: inherit;
        position: absolute;
        background-color: white;
        z-index: 1;

        div {
            cursor: pointer;
            width: 26.5rem;
            background-color: white;
            padding: 5px 15px;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.0;

            &:hover {
                background-color: #007bff;
                color: white;
            }
        }

        &.open {
            max-height: 15rem;
            border: 1px solid #d3d3d3;
            border-top: none;
            overflow-y: scroll;
        }

        &.close {
            height: 0 !important;
            border: none;

            div {
                display: none;
            }
        }
    }
}

#leaf-button-name-selectors button {
    border: none;
    background: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.leaf-button-container {
    display: inline-grid;
    grid-template-columns: max-content max-content max-content max-content max-content;
    grid-template-rows: max-content max-content;

    .leaf-button,
    .leaf-button:focus {
        border: none;
        outline: 0.5px solid #d2d2d2;
        background-color: white;
        padding: 5px;
        min-height: 50px;

        &:first-child {
            border-start-start-radius: 12.5%;
        }

        &:nth-child(5) {
            border-start-end-radius: 12.5%;;
        }

        &:nth-child(6) {
            border-end-start-radius: 12.5%;
        }

        &:last-child {
            border-end-end-radius: 12.5%;
        }

        strong {
            padding: 0.5rem;
            color: #007bff;
        }

        svg > path:first-of-type {
            fill: #007bff;
        }

        &.active,
        &:hover {
            strong {
                color: #006D41;
            }
            svg > path:first-of-type {
                fill: #006D41;
            }
        }
    }
}
