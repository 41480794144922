@import "./colors";
@import "./cursors";

body {
    height: 100vh;
}

/*Elements*/
:disabled {
    cursor: not-allowed;
}

a:hover {
    text-decoration: none;
}

body {
    font-family: Arial, SansSerif, sans-serif;
}

label {
    margin-top: 1rem;
    margin-bottom: -1rem;
}

/*Classes*/
.identilink {
    display: inline-block;
    line-height: 1.25rem;
    margin-left: -1rem;
}

.version {
    font-size: 70%;
    font-weight: 400;
    bottom: -0.25em;
}

.nav-logo {
    max-height: 3.125rem;
    height: 100%;
    width: auto;
}

.blue {
    color: $blue;
}

.dark-blue {
    color: $dark_blue;
}

.brown {
    color: $brown;
}

.green {
    color: $dark_green;
}

.leading {
    line-height: 1.75em;
}

.square {
    height: 2.4rem !important;
    width: 2.4rem !important;
}

.button-height {
    margin-top: 38px;
}

.button-width {
    width: 11rem;
}

label.required::after {
    content: "*";
    color: #dc3545;
    font-weight: 900;
}

.label {
    margin: 1rem 0 -1rem 0;
}

.indent {
    padding-left: 1rem;
}

.round {
    border-radius: 999px;
}

.text-underline {
    text-decoration: underline;
}

.image-wrap {
    shape-outside: margin-box;
}

.flow-root {
    display: flow-root;
}

.no-height {
    height: 0;
}

.rotate-90 {
    transform: rotate(90deg);
}

.radio-button-group {
    height: 38px;
    width: 100%;

    button {
        width: 100%;
        border: thin solid $active_green;
        background-color: white;
        padding: 0 1rem;
        color: $active_green;

        &:first-child {
            border-start-start-radius: 999px;
            border-end-start-radius: 999px;
        }

        &:last-child {
            border-end-end-radius: 999px;
            border-start-end-radius: 999px;
        }

        &.active {
            color: white;
            background-color: $active_green;
        }
    }

}

/*Overrides*/
.fa-pen {
    transform: translateX(-1px);
}

.lead {
    font-size: 1.3125rem;
}

.btn-info {
    background-color: #5bc0de !important;
    border-color: #46b8da !important;
    color: whitesmoke !important;
    border-radius: 6px;
}

.btn-outline-info:hover {
    background-color: #7290c9;
    border-color: #7290c9;
}

.modal-body {
    font-size: larger;
}

.modal-dialog {
    width: calc(100vw - 1rem) !important;
}

.btn-success {
    color: #fff;
    background-color: $dark_green;
    border-color: $dark_green;
}

.btn-outline-success {
    color: $dark_green;
    border-color: $dark_green;
}

.btn-blue {
    color: #fff;
    background-color: $blue;
    border-color: $blue;
}

.btn-outline-info {
    color: $blue;
    border-color: $blue;

    &:hover {
        color: #fff;
        background-color: $blue;
    }
}

.list-group-item {
    padding: 0.5rem;
}

#tree-list {
    max-height: 300px;
    overflow-y: scroll;
    border: thin solid whitesmoke;
}

.w-100-xs {
    width: 100%;
}

.w-75-xs {
    width: 75%;
}

.w-50-xs {
    width: 50%;
}

.w-25-xs {
    width: 25%;
}

.max-content {
    width: max-content;
}
