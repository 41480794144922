#landing {
    position: relative;

    .tree {
        bottom: -4rem;
        z-index: -100;
        opacity: 0.75;
        width: 75vw;
        max-width: 300px;
        position: absolute;
    }
}
