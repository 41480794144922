.nutrition {
    margin: 15px auto 15px auto;
    border: thin solid black;
    width: 100%;
    max-width: 20rem;
    font-size: smaller;

    hr {
        margin: .4rem 0;
    }

    .bar {
        margin: .5rem 0;
        height: 1rem;
        background-color: black;
    }

    .sm-text {
        font-size: 13px;
    }

    .small-image {
        height: 49px;
        width: auto;
    }
}

.hvy {
    margin: .5rem 0;
    height: .5rem;
    background-color: black;
    padding: 0 2rem;
}

.word-break-keep-all {
    word-break: keep-all;
}
