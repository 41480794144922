@import "./colors";

.toggle-button-container {
    cursor: pointer;
    min-height: 38px;
    border: none;
    border-radius: 2rem;
    background-color: $active_green;
    position: relative;
    overflow: hidden;
    transition: background-color 0.2s;

    input {
        opacity: 0;
    }

    :hover {
        cursor: pointer;
    }

    .toggle-button-text {
        height: 100%;
        color: whitesmoke;
        font-weight: bold;
        top: 20%;
        position: absolute;
        width: 100%;

        &.left {
            left: 0;
            padding-left: 12px;
            text-align: left;
            transition: left 0.2s;
        }

        &.right {
            left: 200%;
            text-align: right;
            padding-right: 12px;
            transition: left 0.2s;
        }
    }

    .toggle-button-nob {
        outline: none;
        display: inline-block;
        position: absolute;
        height: 2rem;
        width: 2rem;
        bottom: .25rem;
        margin: -15px 0 0 -5px;
        border-radius: 100%;
        background-color: whitesmoke;
        left: 100%;
        transform: translateX(-100%);
        transition: left 0.2s;
    }

    &.toggled {
        background-color: $active_green;
        border: none;
        transition: background-color 0.2s;

        .left {
            left: -100%;
            transition: left 0.2s;
        }

        .right {
            left: 0;
            transition: left 0.2s;
        }

        .toggle-button-nob {
            left: 10px;
            transform: translateX(0);
            transition: left 0.2s;
        }
    }
}
