@media (min-width: 576px) {
    .w-100-sm {
        width: 100%;
    }
    .w-75-sm {
        width: 75%;
    }
    .w-50-sm {
        width: 50%;
    }
    .w-25-sm {
        width: 25%;
    }
    .hthc {
        font-size: 0.65rem;
    }
}

@media (min-width: 768px) {
    .w-100-md {
        width: 100%;
    }
    .w-75-md {
        width: 75%;
    }
    .w-50-md {
        width: 50%;
    }
    .w-25-md {
        width: 25%;
    }
}

@media (min-width: 992px) {
    .w-100-lg {
        width: 100%;
    }
    .w-75-lg {
        width: 75%;
    }
    .w-50-lg {
        width: 50%;
    }
    .w-25-lg {
        width: 25%;
    }
}

@media (min-width: 1200px) {
    .w-100-xl {
        width: 100%;
    }
    .w-75-xl {
        width: 75%;
    }
    .w-50-xl {
        width: 50%;
    }
    .w-25-xl {
        width: 25%;
    }
}

@media (max-width: 736px) {
    .modal-body {
        font-size: smaller !important;
    }

    .modal-body ul {
        list-style: none;
        padding-left: 10px;
    }
}

@media (max-width: 360px) {
    .negative-side-margins {
        margin: 0 -15px;
    }
    .lead {
        line-height: 1.5rem !important;
    }
    .h3 {
        line-height: 1.875rem !important;
    }
    .button-width {
        width: calc(70vw - 30px) !important;
    }
}
