@import "./colors";

.navbar {
    background-color: $background_light;
    padding: 0 !important;

    &.green-gradient {
        border-bottom: 0.375rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, $background_light 38.1953%, $dark_green);
    }

    &.blue-gradient {
        border-bottom: 0.375rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to right, $background_light 38.1953%, $blue);
    }

    img {
        height: 3rem;
    }

    .navbar-brand {
        padding: 0 !important;
        font-size: 1.5rem;
    }

    a:focus {
        background-color: $background_dark;
        color: $gray;
    }

    li.show, .nav-link.active {
        background-color: $background_dark;
        color: $gray;
    }

    .nav-item {
        .nav-link {
            height: 3.625rem;
            padding-top: 1rem;
            color: #212529 !important;

            &:hover, &.active, &:focus {
                background-color: $background_dark;
                color: $gray;
            }
        }
    }

    .dropdown-item {
        .nav-link {
            height: unset;
            padding: unset;
            color: unset;

            &:hover, &.active {
                background-color: unset;
                color: unset;
            }
        }
    }

    .app-name-container {
        vertical-align: middle;
        display: inline-block;

        .app-name {
            margin: 3px 0 -3px 0;
        }

        .tagline {
            vertical-align: baseline;
            font-size: 0.75rem;
            font-style: italic;
        }
    }
}

// Attempt to keep the menu button from wrapping to the next line.
@media (max-width: 360px) {
    .navbar {
        padding: 0 0.5rem !important;

        img {
            height: 2.5rem;
        }

        .navbar-toggler {
            padding: 0.2rem 0.5rem;
        }

        .navbar-toggler-icon {
            width: 1.25rem;
        }
    }
}