#ppiMap,
#disadvantagedMap {
    height: 300px;
    width: 300px;
    margin: 0 auto;
}

.legend-square {
    height: 1rem;
    width: 1rem;
}

.cejst-legend-container {
    .d-flex > p {
        margin-bottom: 0 !important;
    }

    .disadvantaged-legend {
        height: 1rem;
        width: 1rem;

        &.red {
            background-color: #CA002050;
            border: 2px solid #CA0020;

        }

        &.blue {
            background-color: #0571B050;
            border: 2px solid #0571B0;
        }

        &.black {
            border: 2px solid #000;
        }
    }
}

