$dark_green: #006D41;
$light_green: #9CCF73;
$blue: #7290c9;
$dark_blue: #4f80bd;
$gray: #8F979C;
$brown: #825227;
$background_light: #f8f8f8;
$background_dark: #e7e7e7;

$active_green: #006D41;
