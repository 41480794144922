.font-size-small {
    font-size: small;
}

.input-group input {
    height: var(--bs-btn-line-height)
}

.icon {
    transform: translateX(-1.5px);
}
